import React, { useEffect, useState } from 'react';
import { ToggleSwitch } from '../../components/ToggleSwitch';
import { Button } from '../../components/Button';
import axios from 'axios';
import { getSignature } from '../../utils/getSignature';
import { useDispatch, useSelector } from 'react-redux';
import { getEthersWeb3Provider } from '../../ducks/ethers/web3/selectors';
import { useAccount } from 'wagmi';
import { getProjectConfigAll } from '../../ducks/project/selectors';
import { fetchProject } from '../../ducks/project/action';

import './index.scss';
import { Icons } from '../../assets/icons';

export const Strategies: React.FC = () => {
  const { address: wallet } = useAccount();
  const [spool, setSpool] = useState(false);
  const { strategies } = useSelector(getProjectConfigAll);

  const dispatch = useDispatch();
  const web3 = useSelector(getEthersWeb3Provider);

  useEffect(() => {
    setSpool(strategies.spool);
  }, [strategies]);

  const onSubmit = (): void => {
    (async (): Promise<void> => {
      const { signature, timestamp } = await getSignature(web3, dispatch);

      const formData = new FormData();
      formData.append('strategies', JSON.stringify({ spool }));
      formData.append('signature', signature);
      formData.append('timestamp', timestamp.toString());
      formData.append('wallet', wallet as string);

      await axios.post(process.env.REACT_APP_API + '/project/strategies-config', formData);

      dispatch(fetchProject());
    })();
  };

  return (
    <>
      <h3>Strategies</h3>
      <p className="mb-6">Third party defi strategies for your project </p>
      <div className="strategies modern-step-panel">
        <div className="toggleSwitch flex switchLine">
          <div className="strategy">
            <p>Spool</p>
            <a href={'https://spool.fi/'} target="_blank" rel="noreferrer">
              <img className="open-link-icon" src={Icons['open_link_light']}></img>
            </a>
          </div>
          <ToggleSwitch checked={spool} onChange={(selected): void => setSpool(selected)} />
        </div>
        <div className="submitContainer">
          <Button
            disabled={strategies.spool === spool}
            onClick={(): void => onSubmit()}
            label="Save changes"
            size="medium"
          />
        </div>
      </div>
    </>
  );
};
