import React from 'react';
import { useSelector } from 'react-redux';
import { TabMenu } from '../../components/TabMenu';
import { TabMenuItem } from '../../components/TabMenu/TabMenuItem';
import { Content } from '../Content';
import { SidebarMenu } from '../../components/SidebarMenu';
import { SidebarMenuItem } from '../../components/SidebarMenu/SidebarMenuItem';
import { Route, Switch } from 'react-router-dom';

export interface Props {
  children?: any;
}

export const Main: React.FC<Props> = ({ children }) => {
  return (
    <div className="main">
      <div className="main--header">
        <div className="main-title-text header-lead">Admin panel</div>
        <TabMenu baseLocation="/">
          <TabMenuItem id="pools" title="LM Campaigns" dataCY="lm-campaigns-nav-link" />
          <TabMenuItem id="staking" title="Staking" dataCY="staking-campaigns-nav-link" />
          {process.env.REACT_APP_ENABLE_INFINITE_STAKING === 'true' && (
            <TabMenuItem id="istaking" title="Infinite Staking" dataCY="infinite-staking-nav-link" />
          )}
          <TabMenuItem id="strategies" title="Strategies" />
          <TabMenuItem id="metrics" title="Metrics" />
          <TabMenuItem id="multisig" title="Multisig" />
          <TabMenuItem id="credits" title="Credits" />
          <TabMenuItem id="configuration" title="Configuration" dataCY="configuration-nav-link" />
        </TabMenu>

        <div className="invisible main--title">Admin panel</div>
      </div>
      <Content
        sidebar={
          <Switch>
            <Route path="/pools">
              <SidebarMenu baseLocation="/pools">
                <SidebarMenuItem id="new" title="New LM campaign" icon="plus" />
                <SidebarMenuItem id="active" title="Active" icon="pool" />
                <SidebarMenuItem id="scheduled" title="Scheduled" icon="calendar" />
                <SidebarMenuItem id="awaiting" title="Awaiting scheduling" icon="reload" />
                <SidebarMenuItem id="expired" title="Expired" icon="bin" />
              </SidebarMenu>
            </Route>
            <Route path="/staking">
              <SidebarMenu baseLocation="/staking">
                <SidebarMenuItem id="new" title="New staking campaign" icon="plus" />
                <SidebarMenuItem id="active" title="Active" icon="pool" />
                <SidebarMenuItem id="scheduled" title="Scheduled" icon="calendar" />
                <SidebarMenuItem id="awaiting" title="Awaiting scheduling" icon="reload" />
                <SidebarMenuItem id="expired" title="Expired" icon="bin" />
              </SidebarMenu>
            </Route>
            <Route path="/istaking">
              <SidebarMenu baseLocation="/istaking">
                <SidebarMenuItem id="new" title="New infinite staking pool" icon="plus" />
                <SidebarMenuItem id="active" title="Active" icon="pool" />
                <SidebarMenuItem id="scheduled" title="Scheduled" icon="calendar" />
                <SidebarMenuItem id="awaiting" title="Awaiting scheduling" icon="reload" />
                <SidebarMenuItem id="paused" title="Paused" icon="bin" />
              </SidebarMenu>
            </Route>
            <Route path="/metrics">
              <SidebarMenu baseLocation="/metrics">
                <SidebarMenuItem id="overview" title="Overview" icon="overview" />
                {/*<SidebarMenuItem id="data-table" title="Data Table" icon="data_table" />*/}
              </SidebarMenu>
            </Route>
            <Route path="/multisig">
              <SidebarMenu baseLocation="/multisig">
                <SidebarMenuItem id="admins" title="Admins" icon="admins" />
                <SidebarMenuItem id="transactions" title="Transactions" icon="transactions" />
              </SidebarMenu>
            </Route>
            <Route path="/credits">
              <SidebarMenu baseLocation="/credits">
                <SidebarMenuItem id="payment" title="Payment" icon="wallet" />
              </SidebarMenu>
            </Route>
            <Route path="/configuration">
              <SidebarMenu baseLocation="/configuration" disabled={true}>
                <SidebarMenuItem id="brand" title="Brand" icon="brand_icon" />
                <SidebarMenuItem id="theme" title="Theme" icon="theme_icon" />
                <SidebarMenuItem id="colors" title="Colors" icon="color_icon" />
                {/* <SidebarMenuItem id="dapps_integration" title="DApps Integration" icon="dapps_integration_icon" /> */}
                <SidebarMenuItem id="tokens" title="Tokens" icon="stake" />
                <SidebarMenuItem id="contact_email" title="Contact email" icon="email_icon" />
                <SidebarMenuItem
                  id="third_party_integrations"
                  title="Third Party Integrations"
                  icon="social_media_icon"
                />
                <SidebarMenuItem id="social_media" title="Social media" icon="social_media_icon" />
              </SidebarMenu>
            </Route>
          </Switch>
        }
        mainContent={children}
      />
      <div className="version-showing">{process.env.REACT_APP_VERSION}</div>
    </div>
  );
};
