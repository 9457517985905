import { BigNumber } from 'ethers';
import { createDuration, DurationUnits, durationToUnit } from '../../sdk/helpers/duration';
import { getProtocolContract } from '../../sdk/helpers/getProtocolContract';
import { loadERC20 } from '../../sdk/helpers/loadERC20';
import { LMInterface } from '../../types/dto/Project.dto';
import { accuracy, LMReward, PoolVersions } from '../../utils/helpers';
import { RewardsPoolBase as RewardsPoolBaseV2 } from '../../sdk/staking-v2/RewardsPoolBase';
import { LiquidityMiningPool } from '.';

export async function loadV2(pool: LMInterface): Promise<LiquidityMiningPool> {
  const poolContract = new RewardsPoolBaseV2();
  await poolContract.load(pool.campaignAddress);

  const [
    lpAmount,
    rewardTokens,
    startTimestamp,
    endTimestamp,
    stakingToken,
    isDone,
    extensionDuration,
    contractStakeLimit,
    stakeLimit,
    name,
  ] = await Promise.all([
    poolContract.getTotalStaked(),
    poolContract.getRewardTokens(),
    poolContract.getStartTimestamp(),
    poolContract.getEndTimestamp(),
    poolContract.getStakingToken(),
    poolContract.isDone(),
    poolContract.getExtensionDuration(),
    poolContract.getContractStakeLimit(),
    poolContract.getStakeLimit(),
    poolContract.getName(),
  ]);

  const duration = createDuration(endTimestamp.sub(startTimestamp).toNumber(), DurationUnits.seconds);
  const remainingSeconds = endTimestamp.sub(Date.now()).toNumber();
  const secondsPerWeek = 604800;
  const campaignMessage = pool.campaignMessage || '';

  // Rewards
  const rewardPromises: Promise<any>[] = [];

  async function getReward(index: number): Promise<BigNumber> {
    try {
      return await poolContract.getRewardPerSecond(index);
    } catch (e) {
      return BigNumber.from(0);
    }
  }

  for (let i = 0; i < rewardTokens.length; i++) {
    const rewardContract = loadERC20(rewardTokens[i]);
    rewardPromises.push(getReward(i), rewardContract.getSymbol(), rewardContract.getDecimals());
  }

  const rewardResults: any[] = await Promise.all(rewardPromises);

  const campaignRewards: LMReward[] = [];
  for (let i = 0; i < rewardTokens.length; i++) {
    const rewardPerSecond = rewardResults[i * 3] as BigNumber;
    campaignRewards.push({
      symbol: rewardResults[i * 3 + 1] as string,
      address: rewardTokens[i],
      decimals: rewardResults[i * 3 + 2] as number,
      rewardPerSecond: rewardPerSecond,
      totalRewards: rewardPerSecond
        .mul(durationToUnit(duration, DurationUnits.seconds))
        .div(pool.version === PoolVersions.v4 ? accuracy : 1),
      weeklyRewards: BigNumber.from(rewardPerSecond)
        .mul(secondsPerWeek)
        .div(pool.version === PoolVersions.v4 ? accuracy : 1),
      remainingRewardAmount:
        remainingSeconds > 0
          ? rewardPerSecond.mul(remainingSeconds).div(pool.version === PoolVersions.v4 ? accuracy : 1)
          : BigNumber.from(0),
    });
  }

  // Balances

  const poolTokenContract = await getProtocolContract(pool.dex, stakingToken);

  const [balances, lpSupply, tokenAddresses] = await Promise.all([
    poolTokenContract.getReserves(),
    poolTokenContract.getTotalSupply(),
    poolTokenContract.getTokens(),
  ]);

  const tokens = [];
  const rewards = [];

  for (let i = 0; i < tokenAddresses.length; i++) {
    const tokenAddress = tokenAddresses[i];
    const tokenContract = loadERC20(tokenAddress);

    let excessRewards: BigNumber = BigNumber.from(0);

    if (rewardTokens.includes(tokenAddress))
      excessRewards = await poolContract.getAvailableBalance(rewardTokens.indexOf(tokenAddress));

    tokens.push({
      symbol: await tokenContract.getSymbol(),
      balance: lpAmount.mul(balances[i]).div(BigNumber.from(lpSupply).gt(0) ? lpSupply : BigNumber.from(1)),
      excess: excessRewards,
      address: tokenAddress,
    });
  }

  for (let i = 0; i < rewardTokens.length; i++) {
    const rewardAddress = rewardTokens[i];
    const tokenContract = loadERC20(rewardAddress);

    const excessRewards: BigNumber = await poolContract.getAvailableBalance(i);

    rewards.push({
      symbol: await tokenContract.getSymbol(),
      excess: excessRewards,
      address: rewardAddress,
      decimals: Number(await tokenContract.getDecimals()),
    });
  }

  return {
    address: pool.campaignAddress,
    lpAddress: stakingToken,
    assets: tokens,
    rewards: rewards,
    protocol: pool.dex,
    lpTokenAmount: lpAmount,
    start: new Date(startTimestamp.toNumber() * 1000),
    end: new Date(endTimestamp.toNumber() * 1000),
    done: isDone,
    campaignRewards,
    version:
      pool.version === PoolVersions.v2
        ? PoolVersions.v2
        : pool.version === PoolVersions.v4
        ? PoolVersions.v4
        : PoolVersions.v3,
    name: name,
    campaignMessage: campaignMessage,
    totalLimit: contractStakeLimit,
    walletLimit: stakeLimit,
    extensionDuration: extensionDuration.gt(0)
      ? createDuration(extensionDuration.toNumber(), DurationUnits.seconds)
      : undefined,
  };
}
